@use "./src/scss/global.scss" as *;

.summary-step {
  padding-bottom: 120px;
  .disabled {
    opacity: 0.26;
    pointer-events: none;
  }

  .mt-10 {
    margin-top: 100px;
  }
}

.feedback-box {
  padding: 20px 40px;
  border-radius: 4px;
  background-color: $low-white;
}

.space-right-top {
  right: 20px;
  top: 20px;
}