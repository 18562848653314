@use "./variables.scss" as *;
@use "./mixins.scss" as *;

hr,
a,
button,
div,
span,
label,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.dark {
    color: $black;
  }
}

h1 {
  line-height: 1.4em;
  font-size: 48px;
  font-weight: $bold;
  color: $primary;
}

h2 {
  font-size: 32px;
  line-height: 1.4em;
  font-weight: $semi-bold;
  color: $primary;
}

h3 {
  font-weight: $semi-bold;
  font-size: 24px;
  line-height: 1.4em;
}

h4 {
  font-weight: $regular;
  font-size: 22px;
  line-height: 1.6em;
}

h5 {
  font-weight: $regular;
  font-size: 18px;
  line-height: 1.6em;
}

.title {
  max-width: 530px;
}

.subheader {
  font-size: 20px;
  font-weight: $light;
  letter-spacing: 0.37px;
  line-height: 1.9em;
  color: darken($white, 30%);
}

.hint {
  font-size: 12px;
  font-weight: $light;
  letter-spacing: 0.37px;
  line-height: 1.9em;
  color: darken($white, 30%);
  max-width: 390px;
}

.description {
  font-size: 16px;
  font-weight: $regular;
  letter-spacing: 0.37px;
  line-height: 1.9em;
  color: darken($white, 20%);
  max-width: 400px;
}

.s1 {
  font-size: 0.875rem;
}

.s2 {
  font-size: 0.75rem;
}

.primary {
  color: $primary !important;
}

.error {
  font-size: 12px;
  color: $danger;
  font-weight: $light;
  letter-spacing: 0.37px;
}

button {
  font-size: 16px;
  cursor: pointer;
}

input {
  color: $description !important;
  border-radius: 4px;

  &:active,
  &:focus {
    outline: none;
    border: none;
  }

  &:not([type="file"]) {
    background-color: rgba(27, 26, 23, 0.5);
    border: 1px solid transparent;
    color: $white;

    &::placeholder {
      color: darken($white, 60%) !important;
      font-size: 16px;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px rgba(27, 26, 23, 0.5) inset !important;
      -webkit-text-fill-color: $description !important;
      caret-color: $description;
    }
  }
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border-color: $danger;
}

.ant-input-affix-wrapper {
  background-color: rgba(27, 26, 23, 0.5);
  border: 1px solid transparent;
  color: $white;
  border-radius: 4px;
  padding: 17px 20px;
  font-size: 16px;

  input {
    border-radius: 0px;
    background-color: transparent;
  }
}

.ant-btn {
  font-size: 16px;
  padding: 0.75em 1.5em;
  border-radius: 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  width: max-content;
  border: 1px solid #878787;
  color: #878787;

  &:hover {
    color: $primary;
    border-color: $primary;
  }

  &:disabled {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }

  &-sm {
    padding: 0.5em 1.5em;
  }

  &-md {
    padding: 0.75em 2em;
  }

  &-lg {
    padding: 1em 2.5em;
    min-width: 163px;
    border-radius: 4px;
  }

  &-primary {
    border: 1px solid $primary;
    color: $black;

    &:disabled {
      background-color: rgba(246, 246, 246, 0.08) !important;
    }

    &:hover {
      color: $black;
      border: 1px solid transparent;
    }
  }
}

.ant-table-content {
  overflow-x: auto;
}

table {
  thead {
    th.ant-table-cell {
      background-color: rgba(37, 36, 34, 0.6) !important;
      border-bottom: 1px solid rgba(48, 48, 48, 0.6);
    }
  }

  tbody {
    td.ant-table-cell {
      background-color: rgba(37, 36, 34, 0.9) !important;
      border-bottom: 1px solid rgba(48, 48, 48, 0.6);
      color: darken($white, 30);
      word-break: normal;
      // word-break: break-word;
      // text-overflow: ellipsis;
      // max-height: 50px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 160px;

      // @include ellipsis-after-lines(5);
    }
  }
}

.ant-steps-item {
  &-finish {
    div.ant-steps-item-icon {
      background-color: transparent !important;
      border-color: $white;

      .ant-steps-icon {
        color: $white !important;
      }
    }
    div.ant-steps-item-title {
      color: $white !important;
      &::after {
        background-color: #fff !important;
      }
    }
  }
  &-active {
    div.ant-steps-item-icon {
      background-color: transparent !important;
      .ant-steps-icon {
        color: $primary !important;
      }
    }
    div.ant-steps-item-title {
      color: $primary !important;
    }
  }
  &-title {
    color: rgba(246, 246, 246, 0.3) !important;
    &::after {
      background-color: #505050 !important;
    }
  }
}

.ant-pagination-item {
  border-color: darken($description, 40%);

  &-link {
    border-color: darken($description, 40%) !important;
    color: darken($description, 40%) !important;
  }

  a {
    color: darken($description, 40%);
  }

  &-active {
    border-color: $primary;
    a {
      color: $primary;
    }
  }
}

.ant-alert-success {
  border: 1px solid #07a434;
  background-color: #bfffa6;
}

.ant-alert {
  .ant-alert-message {
    color: $black;
  }

  .ant-alert-description {
    color: $easy-black;
  }

  &-warning {
    background-color: $warning;
  }
}

.ant-alert-error {
  .ant-alert-message {
    color: $white;
  }

  .ant-alert-description {
    color: $description;
    font-size: 14px;
  }
}

.ant-alert-info {
  border: 1px solid #0087e0;
  background-color: $info;
  cursor: pointer;

  svg {
    fill: #0087e0;
  }

  .ant-alert-description {
    color: $low-white;
    font-size: 14px;
    text-decoration: underline;
  }
}

.anticon-close {
  color: $black !important;
}

.ant-select {
  color: $description;
}

.ant-select-dropdown {
  background-color: #1f1f1f;

  .ant-select-item {
    color: $description;

    &[aria-selected="true"] {
      background-color: darken($white, 60%);
    }
  }
}

.warnings-wrapper {
  .ant-alert {
    width: 100%;
  }
}
