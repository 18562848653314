@use "@scss/global.scss" as *;

.layout {
  min-height: 100vh;
  height: max-content;
  width: 100vw;

  &.navbar-content-footer {
    display: flex;
    flex-direction: column;

    .navbar-part {
      // position: fixed;
      // top: 0px;
      // left: 0px;
      width: 100%;
      height: max-content;
    }

    .content-part {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .footer-part {
      width: 100%;
      height: max-content;
    }
  }
  &.navbar-sidebar-content {
    display: flex;
    flex-direction: column;

    .navbar-part {
      width: 100%;
      height: 100%;
      max-height: 60px;
    }

    .bottom-part {
      flex: 1;
      display: flex;
    }

    .sidebar-part {
      height: 100%;
      width: 100%;
      max-width: 256px;
    }

    .content-part {
      flex: 1;
    }
  }
  &.sidebar-navbar-content {
    display: flex;
    flex-direction: row;

    .sidebar-part {
      height: 100%;
      width: 100%;
      max-width: 256px;
    }

    .right-part {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .navbar-part {
      width: 100%;
      height: 100%;
      max-height: 60px;
    }

    .content-part {
      flex: 1;
    }
  }

  .content-part {
    padding: 40px 20px;
    padding-bottom: 120px;
  }
}