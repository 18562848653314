@use "@scss/global.scss" as *;

.tokens-list {
  transition: opacity 0.3s ease-out;

  &.prepared {
    opacity: 0.4;
  }

  .wrapper {
    border-bottom: 1px solid $low-white;
  }

  .title {
    width: 150px;
    padding: 12px 5px;
    color: $description;
  }
}
