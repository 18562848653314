@use "./src/scss/global.scss" as *;

.steps {

  .step {
    margin-top: 64px;
  }

  .mb-10 {
    margin-bottom: 100px;
  }
}