@use "./src/scss/global.scss" as *;

.step2 {


  .lh-2-4 {
    line-height: 1.9em;
  }

  .upload-steps {
    margin-bottom: 40px;
  }

  .description {
    display: flex;
    flex-direction: row;
  }

  .underscore {
    border-bottom: 1px solid $description;
  }

  .full-width {
    max-width: unset;
  }

  .link {
    color: #3c9be8;

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}