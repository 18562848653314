@use "./src/scss/global.scss" as *;

.token-item-wrapper {
  border-bottom: 1px solid $low-white;

  .cell,
  .process-status {
    @include ellipsis-after-lines(1);
    width: 150px;
    opacity: 0.5;
    padding: 12px 5px;
    transition: opacity 0.5s ease-in-out, color 0.5s ease-in-out;
    color: $description;

    &.done {
      color: white;
      opacity: 0.7;
    }

    &.pending {
      color: white;
      opacity: 0.9;
    }

    &.failed {
      color: $danger;
      opacity: 0.9;
    }

    &.retry {
      color: $warning;
      opacity: 0.9;
    }
  }

  .process-status {
    svg {
      transition: fill 0.5s ease-in-out;
    }

    &.done {
      svg {
        fill: $success;
      }
    }

    &.retry {
      svg {
        fill: $warning;
      }
    }

    &.failed {
      svg {
        fill: $danger;
      }
    }
  }

  .retry-btn {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(calc(100% + 16px), -50%);
  }
}
