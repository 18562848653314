@use "./src/scss/global.scss" as *;

.upload-image-input {
  color: transparent !important;
  background-color: transparent;
  visibility: none;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  
  &::before {
    content: "Upload image";
    font-size: 16px;
    padding: 1em 2.5em;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    background-color: #e7a525;
    color: #1d1d1d;
    cursor: pointer;
  }
}

.image-preview {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 600px;

  
}