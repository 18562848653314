.motion {
  width: 100%;
  height: 100%;
}

$speed0: 0.15s;
$speed1: 0.3s;
$speed2: 0.6s;
$speed3: 1s;
$speed4: 1.5s;
$speed5: 3s;


@keyframes spin {
  0% {
    transform: rotate(0deg);
  } 100% {
    transform: rotate(360deg);
  }
}

.spin-0 {
  animation: spin $speed0 linear infinite;
}
.spin-1 {
  animation: spin $speed1 linear infinite;
}
.spin-2 {
  animation: spin $speed2 linear infinite;
}
.spin-3 {
  animation: spin $speed3 linear infinite;
}
.spin-4 {
  animation: spin $speed4 linear infinite;
}
.spin-5 {
  animation: spin $speed5 linear infinite;
}

.spin-0, .spin-1, .spin-2, .spin-3, .spin-4, .spin-5 {
  animation-play-state: running;
  &.paused {
    animation-play-state: paused;
  }
} 

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(4px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-8px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(8px, 0, 0);
  }
}


.shake-0 {
  animation: shake $speed0 linear forwards;
}

.shake-1 {
  animation: shake $speed1 linear forwards;
}

.shake-2 {
  animation: shake $speed2 linear forwards;
}

.shake-3 {
  animation: shake $speed3 linear forwards;
}

.shake-4 {
  animation: shake $speed4 linear forwards;
}

.shake-5 {
  animation: shake $speed5 linear forwards;
}

.shake-0,
.shake-1,
.shake-2,
.shake-3,
.shake-4,
.shake-5 {
  animation-play-state: running;

  &.paused {
    animation-play-state: paused;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  } 100% {
    opacity: 1;
  }
}

.appear-0 {
  animation: appear $speed0 ease-out forwards;
}
.appear-1 {
  animation: appear $speed1 ease-out forwards;
}
.appear-2 {
  animation: appear $speed2 ease-out forwards;
}
.appear-3 {
  animation: appear $speed3 ease-out forwards;
}
.appear-4 {
  animation: appear $speed4 ease-out forwards;
}
.appear-5 {
  animation: appear $speed5 ease-out forwards;
}

@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-top {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes slide-bottom {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.slide {
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  position: fixed;
  height: max-content;
  width: max-content;

  &-left {
    animation-name: slide-left;
    right: 0px;

    &-top {
      top: 0px;
    }
    &-bottom {
      bottom: 0px;
    }
  }
  &-right {
    animation-name: slide-right;
    left: 0px;

    &-top {
      top: 0px;
    }
  
    &-bottom {
      bottom: 0px;
    }
  }
  &-top {
    animation-name: slide-top;
    bottom: 0px;

    &-left {
      left: 0px;
    }
    &-right {
      right: 0px;
    }
  }
  &-bottom {
    animation-name: slide-bottom;
    top: 0px;

    &-left {
      left: 0px;
    }
  
    &-right {
      right: 0px;
    }
  }

  &-left,
  &-right,
  &-top,
  &-bottom {
    &-0 {
      animation-duration: $speed0;
    }
    &-1 {
      animation-duration: $speed1;
    }
    &-2 {
      animation-duration: $speed2;
    }
    &-3 {
      animation-duration: $speed3;
    }
    &-4 {
      animation-duration: $speed4;
    }
    &-5 {
      animation-duration: $speed5;
    }
  }

}

@keyframes pulse {
  0% {
    transform: scale(0.99);
    box-shadow: 0 0 0px 0 rgba(231, 165, 37, 0.7);
  }

  30% {
    transform: scale(1);
    box-shadow: 0 0 30px 20px rgba(231, 165, 37, 0);
  }

  31% {
    box-shadow: 0 0 0px 0px rgba(231, 165, 37, 0);
  }

  100% {
    transform: scale(0.99);
    box-shadow: 0 0 0px 0 rgba(231, 165, 37, 0);
  }
}

.pulse {
  width: max-content;
  height: max-content;
  border-radius: 4px;
  backdrop-filter: blur(20px);
  will-change: transform, box-shadow;

  &-0 {
    animation: pulse $speed0 ease-out infinite;
  }
  &-1 {
    animation: pulse $speed1 ease-out infinite;
  }
  &-2 {
    animation: pulse $speed2 ease-out infinite;
  }
  &-3 {
    animation: pulse $speed3 ease-out infinite;
  }
  &-4 {
    animation: pulse $speed4 ease-out infinite;
  }
  &-5 {
    animation: pulse $speed5 ease-out infinite;
  }
}