@use "./src/scss/global.scss" as *;

.custom-file-input {
  color: transparent !important;
  background-color: transparent;
  visibility: none;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }

  &::before {
    content: "Upload tokens detail";
    font-size: 16px;
    padding: 1em 2.5em;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    background-color: $primary;
    color: $black;
    cursor: pointer;
    
    &:hover {
      background-color: darken($primary, 50%);
    }
  }

  &.disabled {
    &::before {
      opacity: 0.26;
      cursor: not-allowed;
      content: "Pick product category first";
    }
  }
}

.csv-label {
  visibility: none;
  display: none;
  color: $black;
}