@use "./src/scss/global.scss" as *;

.navbar {
  width: 100%;
  height: 64px;
  background-color: $bg-primary;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  justify-content: center;

  .group {
    width: 161px;
    height: 100%;
  }

  a {
    width: 161px;
    height: 100%;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
