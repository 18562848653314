$primary: #e7a525;
$white: #f6f6f6;
$description: #bbbbba;
$very-low-white: #333333;
$low-white: #434343;
$black: #1d1d1d;
$easy-black: lighten($black, 20%);

$success: #07a434;
$danger: #f03535;
$warning: #f0d335;
$info: #67b8ee;

$bg-primary: rgba(27, 26, 23, 0.7);
$bg-secondary: rgba(0, 0, 0, 0.7);

$screen-sizes: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$size-dx: 8px;

$size-1: $size-dx;
$size-2: $size-dx * 2;
$size-3: $size-dx * 3;
$size-4: $size-dx * 4;
$size-5: $size-dx * 5;
$size-6: $size-dx * 6;
$size-7: $size-dx * 7;
$size-8: $size-dx * 8;
$size-9: $size-dx * 9;
$size-10: $size-dx * 10;

$size-variants: (
  1: $size-1,
  2: $size-2,
  3: $size-3,
  4: $size-4,
  5: $size-5,
  6: $size-6,
  7: $size-7,
  8: $size-8,
  9: $size-9,
  10: $size-10
);

$order-variants: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20;

$percentage-variants: (
  100: 100%,
  90: 90%,
  80: 80%,
  70: 70%,
  60: 60%,
  50: 50%,
  40: 40%,
  30: 30%,
  20: 20%,
  10: 10%,
  0: 0%
);

$display-variants: (
  b: block,
  f: flex,
  n: none
);

$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
