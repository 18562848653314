@use "./src/scss/global.scss" as *;

.hint-icon {
  margin-top: 4px;
  color: darken($white, 30%) !important;
  
  svg {
    width: 14px;
    height: 14px;
  }
}