@use "./src/scss/global.scss" as *;

.input-wrapper {
  position: relative; 
  width: 100%;
  
  .max {
    width: 100%;
    position: relative;

    .loader {
      position: absolute !important;
      top: 50%;
      right: -36px;
      transform: translateY(-50%);
      
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }
  
}
