@use "./src/scss/global.scss" as *;

.title-page {
  height: 100%;
  width: 100%;
  text-align: center;
  margin-top: 14vh;

  .hover-disabled {

    &:hover {
      color: initial;
    }
  }
}