@use "@scss/variables.scss" as *;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
  color: $black;
  background-color: $white;
}

body {
  margin: 0px;
  background: radial-gradient(219.25% 118.29% at 95.2% -7.82%, #3D3C3A 0%, #1B1A17 100%);
}

html,
body,
#root,
section {
  height: max-content;
  width: 100%;
}

#root {
  overflow-x: hidden;
}

section {
  position: relative;
  overflow-x: hidden;
}

hr {
  margin: 0px;
  padding: 0px;
}

a {
  color: unset;
  text-decoration: none;
}

button {
  border: none;
  background-color: transparent;
}

#overlay {
  position: absolute;
  height: max-content;
  width: max-content;
  left: 0px;
  top: 0px;
  z-index: 0;

  .alert {
    z-index: 6001;
  }

  .notification {
    z-index: 5001;
  }

  .message {
    z-index: 4001;
  }

  .modal {
    z-index: 3001;
  }

  .dialog {
    z-index: 2001;
  }

  .drawer {
    z-index: 1001;
  }
}